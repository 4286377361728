import React from "react"

import { Helmet } from "react-helmet"

import { SITE_TITLE, THEME_COLOR } from "../utils/siteConfig"

const LayoutComponent = ({ children, pageTitle }) => {
  return (
    <main className={'max-w-fuill'}>
      <Helmet>
        <meta content={THEME_COLOR} name="theme-color" />
        <meta
          content="Bespoke and sustainable wood craft made in Cape Town."
          name="description"
        />
        <meta content="index, follow" name="robots" />
        <meta
          content="Bespoke, Wood, Craft, Reclaimed, Sustainable"
          name="keywords"
        />
        <meta content="https://www.jimbowoodsmith.co.za" property="og:url" />
        <meta content={SITE_TITLE} property="og:site_name" />
        <meta content="Jimbo Smith" name="author" />
        <link
          href={require("../images/favicon.ico").default}
          rel="shortcut icon"
        />
        <link
          href={require("../images/apple-touch-icon.png").default}
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <link
          href={require("../images/favicon-32x32.png").default}
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href={require("../images/favicon-16x16.png").default}
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link
          href={require("../images/safari-pinned-tab.svg").default}
          rel="mask-icon"
          color={THEME_COLOR}
        />
        <meta content={THEME_COLOR} name="msapplication-TileColor" />
        <meta content={THEME_COLOR} name="theme-color" />
        <meta
          property="og:image"
          content={require("../images/social-image.png").default}
        />
        <title>{SITE_TITLE} | {pageTitle}</title>
      </Helmet>
      {children}
    </main>
  )
}

export default LayoutComponent
