import React from "react"

const SocialIconsComponent = props => {
  return (
    <div
      className={
        "inline-flex bg-white rounded-full shadow-lg justify-center text-lg"
      }
      role="group"
    >
      <button className="px-2 py-1 pl-3 mx-0 text-sm tracking-tight text-white uppercase bg-yellow-400 border-r-0 border-gray-800 border-none rounded-l-full outline-none sm:py-2 hover:border-yellow-400 dark:hover:border-gray-800 dark:text-gray-800 dark:bg-yellow-400 dark:border-gray-500 dark:hover:bg-gray-800 dark:hover:text-gray-50 font-title text-normal md:py-1 md:text-lg focus:outline-none hover:bg-yellow-400 hover:text-white focus:shadow-outline opacity-80 hover:opacity-100">
        <a
          className={"transparent"}
          target="_blank"
          rel={"noopener noreferrer"}
          href={
            "https://api.whatsapp.com/send?phone=27833057593&text=Hi%20Jimbo!%20"
          }
        >
          Whatsapp
        </a>
      </button>
      <button className="px-2 py-1 mx-0 text-sm tracking-tight text-white uppercase bg-yellow-400 border-gray-800 border-none outline-none sm:py-2 hover:border-yellow-400 dark:hover:border-gray-800 dark:text-gray-800 dark:bg-yellow-400 dark:border-gray-500 dark:hover:bg-gray-800 dark:hover:text-gray-50 font-title text-normal md:py-1 md:text-lg focus:outline-none hover:bg-yellow-400 hover:text-white focus:shadow-outline opacity-80 hover:opacity-100">
        <a
          className={"transparent"}
          target="_blank"
          rel={"noopener noreferrer"}
          href={"mailto:jimbo@jimbowoodsmith.co.za"}
        >
          Email
        </a>
      </button>
      <button className="px-2 py-1 pr-3 mx-0 text-sm tracking-tight text-white uppercase bg-yellow-400 border-l-0 border-gray-800 border-none rounded-r-full outline-none sm:py-2 hover:border-yellow-400 dark:hover:border-gray-800 dark:text-gray-800 dark:bg-yellow-400 dark:border-gray-500 dark:hover:bg-gray-800 dark:hover:text-gray-50 font-title text-normal md:py-1 md:text-lg focus:outline-none hover:bg-yellow-400 hover:text-white focus:shadow-outline opacity-80 hover:opacity-100">
        <a
          className={"transparent"}
          target="_blank"
          rel={"noopener noreferrer"}
          href={"https://www.instagram.com/jimbowoodsmith/"}
        >
          Instagram
        </a>
      </button>
    </div>
  )
}

export default SocialIconsComponent
