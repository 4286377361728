import React from "react"

import LogoComponent from "./logo"

const HomepageLogoComponent = props => {
  return (
    <div className={"w-3/4 sm:w-1/3 md:w-3/5 h-auto"}>
      <div className="flex items-center w-full text-gray-50 font-title dark:text-gray-50">
        <div className="max-w-lg mx-auto overflow-hidden md:max-w-md mb-1/2 md:block">
          <LogoComponent
            className="w-full h-auto fill-current"
            translateY="50%"
          />
        </div>
      </div>
    </div>
  )
}

export default HomepageLogoComponent
